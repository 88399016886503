import React from "react";

const SelectFilter = props => {
  const { setFilterBy } = props;
  return (
    <select onBlur={evt => setFilterBy(evt.target.value)}>
      <option>-- Filter By --</option>
      {["none"].map(option => {
        return (
          <option value={option} key={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export default SelectFilter;
