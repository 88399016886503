import React from "react";

const SelectVariable = props => {
  const { setShowVariable } = props;
  return (
    <select onBlur={evt => setShowVariable(evt.target.value)}>
      <option value="">-- Select --</option>
      {["pathname", "currency", "store"].map(option => {
        return (
          <option value={option} key={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export default SelectVariable;
