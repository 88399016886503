import React from "react";
import { filterFunction, sortFunction } from "../../utils/upsells";

const LinkListContainer = props => {
  const { linkList, linkListQuery, upsells, sortBy } = props;

  if (linkList.length === 0) {
    return ``;
  } else {
    const trimmedLinkList = linkList.trim().replace(/\/$/, "");
    return (
      <>
        <strong>Link List</strong>
        <ul>
          <strong>upsells</strong>
          {upsells
            .filter(() => filterFunction(props))
            .sort((a, b) => sortFunction(a, b, sortBy))
            .map(({ name, data: { url } }, index) => (
              <li key={index}>
                {name} -{" "}
                <a
                  href={`${trimmedLinkList}${url}/${linkListQuery || `token`}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {trimmedLinkList}
                  {url}/{linkListQuery || `token`}
                </a>
              </li>
            ))}
        </ul>
      </>
    );
  }
};

export default LinkListContainer;
