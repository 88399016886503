import React, { useState } from "react";
import { Helmet } from "react-helmet";
import RefreshDataButton from "../components/RefreshDataButton/RefreshDataButton";
import { filterFunction, sortFunction } from "../utils/upsells";
import ShowHiddenColumnsContainer from "../components/UpsellComponents/ShowHiddenColumnsContainer";
import THeadContainer from "../components/UpsellComponents/THeadContainer";
import ListForm from "../components/UpsellComponents/ListForm";
import LinkListContainer from "../components/UpsellComponents/LinkListContainer";
import TrContainer from "../components/UpsellComponents/TrContainer";
import useLocalStorage from "../hooks/useLocalStorage";
import PasswordInput from "../components/PasswordInput/PasswordInput";
import "../css/common.css";

const UpsellsList = props => {
  const [password] = useLocalStorage("PETLAB_BUILDER_DEVTOOLS_PASSWORD", "");
  const [linkList, setLinkList] = useState("");
  const [linkListQuery, setLinkListQuery] = useState("");
  const [sortBy, setSortBy] = useState("none");
  const [filterBy, setFilterBy] = useState("none");
  const [filterByPathname, setFilterByPathname] = useState("");
  const [filterByDesign, setFilterByDesign] = useState("design2");
  const [filterByTag, setFilterByTag] = useState("Probiotic Chews");
  const [showVariable, setShowVariable] = useState("pathname");
  const [tableBuilder, setTableBuilder] = useState(true);
  const [tableStaging, setTableStaging] = useState(false);
  const [tableLive, setTableLive] = useState(true);
  const [tableLocal, setTableLocal] = useState(true);
  const [tableData, setTableData] = useState(true);
  const [tableProductSelector, setTableProductSelector] = useState(false);
  const [showLiveUrl, setShowLiveUrl] = useState(false);
  const [showStagingUrl, setShowStagingUrl] = useState(false);

  const upsells = JSON.parse(props.pageContext.upsells);
  const allUpsellCheckoutData = JSON.parse(
    props.pageContext.allUpsellCheckoutData
  );

  const showList = evt => {
    setLinkList(evt.target.value);
  };

  const showListQuery = evt => {
    setLinkListQuery(evt.target.value);
  };

  const states = {
    ...props,
    upsells,
    allUpsellCheckoutData,
    tableBuilder,
    setTableBuilder,
    tableStaging,
    setTableStaging,
    tableLive,
    setTableLive,
    tableLocal,
    setTableLocal,
    tableProductSelector,
    setTableProductSelector,
    tableData,
    setTableData,
    sortBy,
    setSortBy,
    linkList,
    setLinkList,
    linkListQuery,
    setLinkListQuery,
    filterBy,
    setFilterBy,
    filterByPathname,
    setFilterByPathname,
    filterByDesign,
    setFilterByDesign,
    filterByTag,
    setFilterByTag,
    showVariable,
    setShowVariable,
    showLiveUrl,
    showStagingUrl,
    setShowLiveUrl,
    setShowStagingUrl,
    showList,
    showListQuery
  };

  return (
    <>
      {password?.length > 0 &&
      password === process.env.GATSBY_DEVTOOLS_PASSWORD ? (
        <div style={{ padding: 20 }}>
          <RefreshDataButton />
          <ListForm {...states} />
          <LinkListContainer {...states} />
          <ShowHiddenColumnsContainer {...states} />
          <table
            border="1"
            cellPadding="2"
            cellSpacing="0"
            style={{ width: "100%", border: "1px solid #000" }}
          >
            <THeadContainer {...states} />
            <tbody>
              {upsells
                .filter(() => filterFunction(states))
                .sort((a, b) => sortFunction(a, b, sortBy))
                .map((upsell, index) => (
                  <TrContainer {...upsell} {...props} key={index} {...states} />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <PasswordInput />
      )}
      <Helmet>
        <title>Petlab - Builder - Developer Tools</title>
      </Helmet>
    </>
  );
};

export default UpsellsList;
