import React from "react";

const ShowVariableContainer = props => {
  const { url, showVariable } = props;

  switch (showVariable) {
    default:
      return <>{url}</>;
  }
};

export default ShowVariableContainer;
