import React from "react";
import ShowVariableContainer from "./ShowVariableContainer";

const FUNNEL_ROOT_URL_LIVE =
  process.env.GATSBY_UPSELL_ROOT_URL_LIVE ?? "https://offer.thepetlabco.com";
const FUNNEL_ROOT_URL_STAGING =
  process.env.GATSBY_UPSELL_ROOT_URL_STAGING ??
  "https://staging-builder-gatsby-funnel.netlify.app";

const TrContainer = props => {
  const { name, data, id: pageId } = props;

  const {
    tableBuilder,
    tableStaging,
    tableLive,
    tableLocal,
    tableProductSelector,
    tableData,
    showLiveUrl,
    showStagingUrl
  } = props;

  const { url } = data;
  const productsId = data?.selector?.id;
  const productsDataId = data?.selector?.value?.name;

  return (
    <tr>
      <td width="40%">{name}</td>
      <td
        width="7%"
        align="center"
        style={{
          fontSize: "10px",
          display: tableBuilder ? "table-cell" : "none"
        }}
      >
        <a
          href={`https://builder.io/content/${pageId}`}
          rel="noreferrer"
          target="_blank"
        >
          Edit Page
        </a>{" "}
        &middot;
        <a
          href={`https://builder.io/content/${productsId}`}
          rel="noreferrer"
          target="_blank"
        >
          Edit Selector
        </a>
      </td>
      <td
        width="5%"
        align="center"
        style={{ display: tableStaging ? "table-cell" : "none" }}
      >
        <a
          href={`${FUNNEL_ROOT_URL_STAGING}${url}/token`}
          rel="noreferrer"
          target="_blank"
        >
          {showStagingUrl ? `${FUNNEL_ROOT_URL_STAGING}${url}` : "Staging"}
        </a>
      </td>
      <td
        width="5%"
        align="center"
        style={{ display: tableLive ? "table-cell" : "none" }}
      >
        <a
          href={`${FUNNEL_ROOT_URL_LIVE}${url}/token`}
          rel="noreferrer"
          target="_blank"
        >
          {showLiveUrl ? `${FUNNEL_ROOT_URL_LIVE}${url}` : "Live"}
        </a>
      </td>
      <td
        width="5%"
        align="center"
        style={{ display: tableLocal ? "table-cell" : "none" }}
      >
        <a href={`${url}/token`} rel="noreferrer" target="_blank">
          Local
        </a>
      </td>
      <td
        width="5%"
        align="center"
        style={{ display: tableLocal ? "table-cell" : "none" }}
      >
        <a
          href={`/upsell-preview?url=${url}&token=token`}
          rel="noreferrer"
          target="_blank"
        >
          Preview
        </a>
      </td>
      <td
        width="10%"
        align="left"
        style={{
          display: tableProductSelector ? "table-cell" : "none",
          wordBreak: "break-all"
        }}
      >
        <small>
          <a
            href={`/devtools/data?path=${url}`}
            rel="noreferrer"
            target="_blank"
          >
            {productsDataId}
          </a>
        </small>
      </td>
      <td
        width="40%"
        align="left"
        style={{ display: tableData ? "table-cell" : "none" }}
      >
        <ShowVariableContainer {...data} {...props} />
      </td>
    </tr>
  );
};

export default TrContainer;
