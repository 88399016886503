export const filterFunction = props => {
  const { item, filterBy, filterByPathname } = props;
  switch (filterBy) {
    case "pathname":
      return item.data.url === filterByPathname ? true : false;
    default:
      return true;
  }
};

export const sortFunction = (a, b, sortBy) => {
  switch (sortBy) {
    case "name-asc":
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    case "name-desc":
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    case "pathname-asc":
      if (a.data.url < b.data.url) {
        return -1;
      }
      if (a.data.url > b.data.url) {
        return 1;
      }
      return 0;
    case "pathname-desc":
      if (a.data.url < b.data.url) {
        return 1;
      }
      if (a.data.url > b.data.url) {
        return -1;
      }
      return 0;
    default:
      return 0;
  }
};
