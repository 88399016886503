import React from "react";

const SelectSort = props => {
  const { setSortBy } = props;

  return (
    <select onBlur={evt => setSortBy(evt.target.value)}>
      <option>-- Sort By --</option>
      {["name-asc", "name-desc", "pathname-asc", "pathname-desc", "none"].map(
        option => {
          return (
            <option value={option} key={option}>
              {option}
            </option>
          );
        }
      )}
    </select>
  );
};

export default SelectSort;
